import { saveAs } from 'file-saver'
import useJwt from '@/auth/jwt/useJwt'

export async function getDownloadArquivoAws(s3Key: string) {
  const response = await useJwt.post('aws/getDownladArquivoAwsCrypt', { s3Key: s3Key })
  const data = response.data
  const buffer = new Uint8Array(data.Body.data)
  const blob = new Blob([buffer], { type: data.ContentType })
  saveAs(blob, 'arquivo.pdf')
  const url = window.URL.createObjectURL(blob)
  window.open(url, '_blank')
  setTimeout(() => window.URL.revokeObjectURL(url), 100)
}
