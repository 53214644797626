import useJwt from '@/auth/jwt/useJwt'

/**
 * Faz o download de um arquivo do AWS S3.
 * @param s3Key A chave do arquivo no AWS S3.
 * @param filename O nome do arquivo a ser salvo.
 * @returns Uma Promise que resolve com true quando o download é concluído com sucesso.
 */
export default async function downloadFileAWS(s3Key: string, filename: string) {
  return new Promise((resolve, reject) => {
    const fileName = filename
    const param = { s3Key: s3Key }
    useJwt
      .post(`aws/getDownladArquivoAwsCrypt`, param)
      .then((response) => {
        const data = response.data
        const blob = createBlobFromBuffer(data)
        const { a, url } = prepareDownloadLink(blob, fileName)
        executeDownload(a, url)
        resolve(true)
      })
      .catch((error) => {
        console.error(error)
        reject(error)
      })
  })
}

/**
 * Cria um objeto Blob a partir de um buffer.
 *
 * @param data - Os dados contendo o buffer.
 * @returns O objeto Blob criado.
 */
function createBlobFromBuffer(data: any) {
  const buffer = new Uint8Array(data.Body.data) // Extrai o buffer dos dados
  const blob = new Blob([buffer], { type: data.ContentType })
  return blob
}

/**
 * Prepara um link de download para um arquivo Blob.
 *
 * @param blob O objeto Blob que representa o arquivo a ser baixado.
 * @param fileName O nome do arquivo a ser baixado.
 * @returns Um objeto contendo o elemento <a> criado e a URL do arquivo Blob.
 */
function prepareDownloadLink(blob: Blob, fileName: string) {
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.download = fileName
  document.body.appendChild(a)
  return { a, url }
}

/**
 * Executa o download de um arquivo usando um elemento âncora HTML.
 * @param a O elemento âncora HTML usado para iniciar o download.
 * @param url A URL do arquivo a ser baixado.
 */
function executeDownload(a: HTMLAnchorElement, url: string) {
  a.click()
  window.URL.revokeObjectURL(url)
}
