<template>
  <div>
    <!-- <b-progress :max="100">
      <b-progress-bar
        animated
        :value="progress"
        variant="primary"
        class="progress-bar-primary"
        :label="`${((progress / 100) * 100).toFixed(2)}%`"
      />
    </b-progress> -->
    <b-form-file
      class="mt-1"
      multiple
      v-model="files"
      ref="file"
      placeholder="Escolha um arquivo"
      drop-placeholder="Deixe seu arquivo aqui..."
      browse-text="Buscar"
      :file-name-formatter="formatNames"
    />
    <div class="alert alert-light" role="alert">{{ message }}</div>
    <div class="card">
      <div class="card-header">Lista de arquivos enviados</div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item" v-for="(file, index) in files" :key="index">
          <span>{{ file.name }}</span>
        </li>
      </ul>
    </div>
    <div class="mt-1">
      <b-button variant="btn btn-success" @click="upload"> Enviar Arquivo </b-button>
    </div>
  </div>
</template>
<script>
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    props: {
      parametro: {},
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        selectedFiles: [],
        currentFile: undefined,
        progress: 0,
        message: '',
        fileInfos: [],
        files: null,
      }
    },
    methods: {
      formatNames(files) {
        if (files.length === 1) {
          return files[0].name
        }
        return `${files.length} arquivos selecionados`
      },
      onProgress(percent) {},
      async upload() {
        this.progress = 0

        for (const file of this.$refs.file.files) {
          const formData = new FormData()
          // Normaliza o nome do arquivo
          let normalizedFileName = this.normalizeFileName(file.name)
          // Cria um novo File com o nome normalizado
          let normalizedFile = new File([file], normalizedFileName, {
            type: file.type,
          })

          formData.append('userId', this.userData.id)
          formData.append('tomadorId', this.parametro.id)

          formData.append('file', normalizedFile)
          formData.append('modulo', 'Tomador')
          formData.append('nomeArquivo', file.name)
          formData.append('sigla', this.userData.Instituto.sigla)

          await useJwt
            .postDocumento('/aws/uploadArquivoAWS', () => {}, formData)
            .then((response) => {
              if (response.data.status == 'erro') {
                console.error(response.data.mensagem)
                this.loading = false
                return
              } else {
                this.$message.success('Arquivo(s) anexado(s) com sucesso!')
                this.loading = false
                // window.open(response.data.s3Url)
              }
            })
            .catch((error) => {
              console.error(error)
              this.$message.error('Erro ao anexar arquivo(s)!')
              this.loading = false
              this.$emit('atualizarGrid')
              return
            })
        }
        this.selectedFiles = undefined
        this.$emit('fecharModalUploadArquivo')
      },
      normalizeFileName(fileName) {
        return fileName
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/[^a-zA-Z0-9.]/g, ' ')
      },
    },
  }
</script>
